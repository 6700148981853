import React from "react";
import { Routes, Route } from "react-router-dom";

// views without layouts

import Landing from "views/Landing.js";
import Privacy from "views/Privacy";


function App() {

    React.useEffect(() => {
        if (window.location.pathname === '/download') {
            window.location.href = 'https://chromewebstore.google.com/detail/wizard-of-swipe-for-tinde/fpdnkpeiopkpmkcnagncpokfcpoejmnl';
        }
    }, []);

    
    return (
        <Routes>
            <Route path="/download" element={<></>} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/*" element={<Landing />} />
        </Routes>
    );
}

export default App;