import React from "react";

// components

import Navbar from "components/Navbars/AuthNavbar.js";

export default function Landing() {

  return (
    <>
      <Navbar transparent />
      <section>
        <div className="relative pt-16 flex content-center items-center justify-center min-h-screen md:h-screen">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-gradient flex items-center justify-center"
          >
          </div>

          <div className="container relative mx-auto h-full flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="text-center py-6 md:py-0 h-full flex flex-col items-center justify-center md:w-6/12">
              <img src="/screenshot-rocks.png" alt="screenshot" className="h-full object-contain" />
            </div>
            <div className="px-4 py-6 md:py-0 ml-auto mr-auto text-center h-full flex flex-col items-center justify-center md:w-6/12">
              <div className="relative inline-block">
                <h1
                  className="font-semibold text-white text-7xl"
                  tabIndex="0"
                >
                  Run your Tinder on Autopilot
                </h1>
                <p className="text-white text-2xl mt-6 px-12">
                  The Wizard uses AI to swipe and chat for you on Tinder.
                  <br />
                  Get it now for just <del>$30</del> $9.
                </p>
              </div>
              <a href="https://buy.stripe.com/dR68yH3QRdll8iQ7sv">
                <button type="button" className="mt-8 text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-xl px-5 py-2.5 text-center">
                  Upgrade your Tinder
                </button>
              </a>
              <a href="/download" className="mt-2 text-white text-sm">
                Download the Wizard's extension
              </a>
            </div>
          </div>
        </div>

      </section>
      <section>
        <div className="relative flex content-center items-center justify-center min-h-screen xl:h-screen">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover bg-blueGray-50 flex items-center justify-center"
          >
          </div>

          <div className="container relative mx-auto h-full flex flex-col-reverse md:flex-row justify-center items-center">
            <div className="px-4 py-6 md:py-0 ml-auto mr-auto text-left h-full flex flex-col items-center justify-center md:w-6/12 md:pr-12">
              <div className="relative inline-block">
                <h3
                  className="font-bold text-5xl mb-12"
                  tabIndex="0"
                >
                  Discover the powers of the Wizard
                </h3>
                <h4 className="text-2xl font-semibold mt-6">
                  💬 Generate Chat Messages
                </h4>
                <p className="text-xl mt-2">
                  {`
The Wizard uses AI to analyze both your profile and your matches' profiles, review chat history, and generate messages to keep the conversation flowing smoothly.
                  `}
                </p>
                <h4 className="text-2xl font-semibold mt-6">
                  💄 Beauty Score
                </h4>
                <p className="text-xl mt-2">
                  {`
The Wizard evaluates user images and applies AI to assign a score based on attractiveness.
                  `}
                </p>
                <h4 className="text-2xl font-semibold mt-6">
                  🕵️‍♂️ Premium Filters
                </h4>
                <p className="text-xl mt-2">
                  {`
Filter matches based on user-provided information. The Wizard can filter profiles by zodiac signs, pet preferences, and more, giving you endless possibilities.
                  `}
                </p>
                <h4 className="text-2xl font-semibold mt-6">
                  👩🏽‍🤝‍🧑🏻 Ethnicity
                </h4>
                <p className="text-xl mt-2">
                  {`
The Wizard can identify the ethnicity of users through image analysis, allowing you to filter users based on this attribute.
                  `}
                </p>
              </div>
            </div>
            <div className="text-center py-6 md:py-0 h-full flex flex-col items-center justify-center md:w-6/12">
              <img src="/chat_screenshot_vertical_big.png" alt="screenshot" className="h-full object-contain" />
            </div>
          </div>
        </div>

      </section>
    </>
  );
}